
.parallax-container .parallax img{
    opacity: 1;
}
#asasas img{
    width: 100vw;
}
#mockk .col{
    margin: 0;
    padding: 1%;
    border-radius: 12%;
}
