  [data-aos="rotate-c"] {
    transition-property: animation;
  }
  [data-aos="rotate-c"].aos-animate {
    transition: ease-in;
    transition-property: animation;
    /* background-image: linear-gradient(45deg, #47bb60, #826eee); */
    background-image: linear-gradient(45deg, #0095ff, #00f36194);
    animation: exampleB03 2s;
  }

  [data-aos="rotate-skil"] {
    transition-property: animation;
  }
  [data-aos="rotate-skil"].aos-animate {
    transition: ease-in;
    transition-property: animation;
    background-image: linear-gradient(0deg, #79a3d7, transparent);
    animation: example13 3s;
  }

 

  @keyframes exampleB03 {
    0% {
      clip-path: polygon(50% 50%, 10% 10%, 20% 0, 20% 0, 0 0, 0 20%, 0 20%, 10% 10%, 50% 50%, 90% 10%, 80% 0, 80% 0, 100% 0, 100% 20%, 100% 20%, 90% 10%, 50% 50%, 90% 90%, 100% 80%, 100% 80%, 100% 100%, 80% 100%, 80% 100%, 90% 90%, 50% 50%, 10% 90%, 0 80%, 0 80%, 0 100%, 20% 100%, 20% 100%, 10% 90%, 50% 50%);
    }
    33% {
      clip-path: polygon(50% 50%, 25% 25%, 50% 0, 50% 0, 0 0, 0 50%, 0 50%, 25% 25%, 50% 50%, 75% 25%, 50% 0, 50% 0, 100% 0, 100% 50%, 100% 50%, 75% 25%, 50% 50%, 75% 75%, 100% 50%, 100% 50%, 100% 100%, 50% 100%, 50% 100%, 75% 75%, 50% 50%, 25% 75%, 0 50%, 0 50%, 0 100%, 50% 100%, 50% 100%, 25% 75%, 50% 50%);
    }
    66% {
      clip-path: polygon(50% 50%, 25% 25%, 50% 50%, 50% 0, 0 0, 0 50%, 0 50%, 25% 25%, 50% 50%, 75% 25%, 50% 50%, 50% 0, 100% 0, 100% 50%, 100% 50%, 75% 25%, 50% 50%, 75% 75%, 100% 50%, 100% 50%, 100% 100%, 50% 100%, 50% 50%, 75% 75%, 50% 50%, 25% 75%, 0 50%, 0 50%, 0 100%, 50% 100%, 50% 50%, 25% 75%, 50% 50%);
    }
    100% {
      clip-path: polygon(50% 50%, 25% 25%, 50% 50%, 50% 0, 0 0, 0 50%, 50% 50%, 25% 25%, 50% 50%, 75% 25%, 50% 50%, 50% 0, 100% 0, 100% 50%, 50% 50%, 75% 25%, 50% 50%, 75% 75%, 50% 50%, 100% 50%, 100% 100%, 50% 100%, 50% 50%, 75% 75%, 50% 50%, 25% 75%, 50% 50%, 0 50%, 0 100%, 50% 100%, 50% 50%, 25% 75%, 50% 50%);
    }
  }
  
  @keyframes example13 {
	0% {
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0 0);  
	}
	25%{
    clip-path: polygon(30% 0%, 70% 0%, 62% 39%, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0 0);

  }
  50%{
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 100% 100%, 0 100%, 0% 70%, 40% 53%);

  }
  75%{
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 100% 100%, 36% 53%, 0% 70%, 0 0);

  }
  100%{
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 59% 65%, 0 100%, 0% 70%, 0 0);


  }
	
}

